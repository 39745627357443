import React from "react";
import {
	Box,
	Typography,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import HeaderBlock from "../utils/HeaderBlock";

const Speakers = () => {
	// localisation
	const { t, i18n } = useTranslation();
	const location = useLocation();
	const currentLang = location.pathname.split('/')[1];

	function createData(nameStr, affilStr, titleStr) {
		return { nameStr, affilStr, titleStr };
	}

	const rows = [
		createData(
			'Tim Moore',
			'Cipher Consulting Pty. Ltd.',
			'The of Role of the National Greenhouse and Energy Reporting (NGER) Act and the Safeguard Mechanism in Australian Net-Zero Aspirations: A Geologist\'s Perspective'
		),
		createData(
			'Peter Morris',
			'Minerals Council of Australia (MCA) /Low Emission Technology Australia (LETA)',
			'Government/industry cooperation in developing Australia\'s coal industry National Greenhouse and Energy Reporting requirements'
		),
		createData(
			'Dr Abouna Saghafi',
			'UNSW/CSIRO',
			'Overview of measurement of gas emissions from Australian coal mines and mitigation strategies'
		),
		createData(
			'Matthew Fellowes',
			'ACARP',
			'Title TBC'
		),
		createData(
			'Iain Hornshaw',
			'Yancoal Australia',
			'Coal mine emissions reduction, an operator\'s perspective'
		),
		createData(
			'Rosie Johnstone',
			'CCUS Network Australia',
			'Title TBC'
		),

		createData(
			'Daein Cha',
			'deepC Store Pty Ltd',
			'Large-scale long-distance CCS value chain development to decarbonise Asia Pacific\'s hard-to-abate sectors'
		),
		createData(
			'Scott Thomson',
			'Coalbed Innovation Pty Ltd',
			'The Application of Yabby Geosensing and Gas Reservoir Technology to Improving Mine Decarbonisation Outcomes'
		),

		createData(
			'Prof Ting Ren',
			'University of Wollongong',
			'Longwall Goaf as a Reservoir for Mine Gas Storage and Sequestration: A Novel Decarbonisation Strategy for Underground Coal Mines, University of Wollongong'
		),
		createData(
			'A/Prof Simit Raval',
			'UNSW',
			'Coal Mine Methane Emission Estimates: Bridging Data Gaps to Drive Decarbonisation'
		),
		createData(
			'Prof Ray Johnson',
			'Novus Energy/ University of Queensland',
			'Successful Implementation of Two Decades Of Australian Coal Seam Gas Research To Improve Coal Mine Methane Pre-Drainage And Reduce Mine Emissions'
		),
		createData(
			'Dr Jing Zhao',
			'UNSW',
			'Microbial Contributions to Methane Emission Control in Coal Mines'
		),
		createData(
			'James Tauchnitz',
			'Novus Energy',
			'Improvements in Surface Coal Mine Fugitive Coal Mine Methane Emissions Estimation and Implementation of Subterranean Barriers with Pre-Drainage to Achieve Substantive Emissions Reductions'
		),
		createData(
			'Dr Yonggang Jin',
			'CSIRO',
			'CSIRO R&D in Ventilation Air Methane Abatement'
		),
		createData(
			'Dr Yongxing Zhang/ Behdad Moghtaderi',
			'University of Newcastle',
			'VAM Abatement and Safety Research at the University of Newcastle'
		),
		createData(
			'Daniel Sepúlveda',
			'Palaris',
			'Integrating VAM Abatement into Early-Stage Mine Ventilation Design'
		),
	];

	return (
		<Stack spacing={0} sx={{ mt: 5, width: '100%' }}>
			<HeaderBlock />
			<Box display="flex" justifyContent="center" alignItems="center" sx={{ mb: 3, width: '100%' }}>
				<Box sx={{ maxWidth: '1000px', width: '90%', pl: 5, pr: 5, pt: 0, pb: 5 }}>
					<TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
						<Table sx={{ width: '100%' }}>
							<TableHead sx={{ bgcolor: '#C2D6C5' }}>
								<TableRow>
									<TableCell>Name</TableCell>
									<TableCell>Company</TableCell>
									<TableCell>Presentation Title</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{rows.map((row) =>(
									<TableRow
										key={row.nameStr}
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										<TableCell component="th" scope="row">
											{row.nameStr}
										</TableCell>
										<TableCell>{row.affilStr}</TableCell>
										<TableCell>{row.titleStr}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			</Box>

		</Stack>
	);
};

export default Speakers;