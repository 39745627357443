import React, { useState } from "react";
import { Box, Typography, Stack, Paper, Grid } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";

import HeaderBlock from "../utils/HeaderBlock";

const Img = styled('img')({
	margin: 0,
	display: 'block',
	width: '100%'
});

const Sponsors = () => {
	// localisation
	const { t, i18n } = useTranslation();
	const location = useLocation();
	const currentLang = location.pathname.split('/')[1];

	return (
		<Stack spacing={0} sx={{ mt: 5, width: '100%' }}>
			<HeaderBlock />
			<Stack spacing={0} justifyContent="center" alignItems={"center"} sx={{ mt: 5, width: '100%', mb: 10 }}>
				<Stack spacing={5} direction="row" justifyContent="center" sx={{ display: { xs: 'none', md: 'flex' } }}>
					<Img alt={'NFACR'} src={'/logos/nfacr-logo.png'} sx={{ height: '20vh', cursor: 'pointer' }}
						onClick={() => window.open('https://www.australiachinafoundation.org.au/', '_blank')}
					/>
					<Img alt={'UNSW Sydney'} src={'/logos/unsw-logo.png'} sx={{ height: '15vh', cursor: 'pointer' }}
						onClick={() => window.open('https://www.unsw.edu.au/', '_blank')}
					/>
					<Img alt={'CUMT'} src={'/logos/cumt-logo.png'} sx={{ height: '15vh', cursor: 'pointer' }}
						onClick={() => window.open('https://global.cumt.edu.cn/', '_blank')}
					/>
					<Img alt={'SPU'} src={'/logos/spu-logo.png'} sx={{ height: '15vh', cursor: 'pointer' }}
						onClick={() => window.open('https://www.swpu.edu.cn/en/', '_blank')}
					/>
				</Stack>
				<Stack direction="column" spacing={5} key="hosts" justifyContent={'center'} alignItems={'center'} sx={{ display: { xs: 'flex', md: 'none' } }}>
					<Img alt={'NFACR'} src={'/logos/nfacr-logo.png'} sx={{ width: '50%', cursor: 'pointer' }}
						onClick={() => window.open('https://www.australiachinafoundation.org.au/', '_blank')}
					/>
					<Img alt={'UNSW Sydney'} src={'/logos/unsw-logo.png'} sx={{ width: '25%', cursor: 'pointer' }}
						onClick={() => window.open('https://www.unsw.edu.au/', '_blank')}
					/>
					<Img alt={'CUMT'} src={'/logos/cumt-logo.png'} sx={{ width: '25%', cursor: 'pointer' }}
						onClick={() => window.open('https://global.cumt.edu.cn/', '_blank')}
					/>
					<Img alt={'SPU'} src={'/logos/spu-logo.png'} sx={{ width: '25%', cursor: 'pointer' }}
						onClick={() => window.open('https://www.swpu.edu.cn/en/', '_blank')}
					/>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default Sponsors;