import React from "react";
import {
  Box,
  Typography,
  Button,
  Stack,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import HeaderBlock from "../utils/HeaderBlock";

const SeedFund = () => {
  // localisation
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const currentLang = location.pathname.split('/')[1];

  return (
    <Stack spacing={0} sx={{ mt: 5, width: '100%' }}>
      <HeaderBlock />
      <Box display="flex" justifyContent="center" sx={{ mt: 5, mb: 3, pl: 5, pt: 5, width: '100%' }}>
        <Typography variant="h4" sx={{ color: '#3E4F44' }}>
          UNSW-CUMT/SWPU Collaborative Research Program 2025
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={1} md={1.5} />
        <Grid item xs={10} md={9}>
          <Stack spacing={0} sx={{ mb: 10 }}>
            <Typography variant="body1" sx={{ mt: 2, mb: 2, color: '#5E8D66', fontWeight: 'bold' }}>
              Congratulations to the winning teams!
            </Typography>
            <Typography variant="body1" sx={{ mt: 0 }}>
              See below for details on the funded teams for each type of seed grant:
            </Typography>
            <List sx={{ listStyleType: 'disc' }}>
              <ListItem disableGutters sx={{ display: 'list-item' }}>
                <ListItemText primary="Seed grant No.1: Reducing fugitive emissions (methane) from resource extractions for collaboration between Chinese and Australian research partners. Grant amount: AUD 25,000" />
                <Typography variant="body1" sx={{ mt: 2, mb: 2, color: 'black' }}>
                  Team lead investigators: <br/>Dr Xin Zhang (UNSW), xin.zhang15@unsw.edu.au; and <br />Dr Qingquan Liu (CUMT), cumtsafe@cumt.edu.cn
                </Typography>
              </ListItem>
              <ListItem disableGutters sx={{ display: 'list-item' }}>
                <ListItemText primary="Seed grant No.2: Carbon capture and storage for collaboration between Chinese and Australian research partners. Grant amount: AUD 25,000" />
                <Typography variant="body1" sx={{ mt: 2, mb: 2, color: 'black' }}>
                  Team lead investigators: <br/>Dr Yu Jing (UNSW), yu.jing@unsw.edu.au; and <br />Prof Yulong Zhao (SWPU), swpuzhao@swpu.edu.cn
                </Typography>
              </ListItem>
            </List>
            <Typography variant="body1" sx={{ mt: 0, mb: 2, color: '#5E8D66', fontWeight: 'bold' }}>
              Anticipated grant commencement: 24/04/2025 <br />
              Anticipated grant end date: 01/05/2026
            </Typography>

          </Stack>
        </Grid>
        <Grid item xs={1} md={1.5} />
      </Grid>
    </Stack>
  );
};

export default SeedFund;