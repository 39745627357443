import React from "react";
import {
	Box,
	Typography,
	Stack,
	Paper,
	Divider
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HeaderBlock from "../utils/HeaderBlock";

const Program = () => {
	// localisation
	const { t, i18n } = useTranslation();
	const location = useLocation();
	const currentLang = location.pathname.split('/')[1];

	return (
		<Stack spacing={0} sx={{ mt: 5, width: '100%' }}>
			<HeaderBlock />
			<Box
				sx={{
					display: { xs: 'none', md: 'flex' },
					flexWrap: 'wrap',
					justifyContent: 'center',
					gap: 5,
					position: 'relative',
					width: '100%',
					bgcolor: 'white',
					pl: 20,
					pr: 20,
					pt: 10,
					mb: 10
				}}
			>
				<Paper square
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						boxShadow: 3,
						width: '350px',
						minHeight: '450px',
						backgroundColor: 'white',
						textTransform: 'none',
						textDecoration: 'none'
					}}
				>
					<Box sx={{ bgcolor: 'transparent', width: '100%', padding: 3 }}>
						<Stack spacing={0} sx={{ width: '100%' }}>
							<Typography variant="h6" sx={{ fontWeight: "bold", color: '#3E4F44', mb: 2 }}>
								11 Apr
							</Typography>
							<Divider />
							<Typography variant="body1" sx={{ mt: 2, color: "#5E8D66" }}>
								9:00AM - 5:30PM, at Sheraton Xuzhou Hotel
							</Typography>
							<Typography variant="body1" sx={{ mt: 2, color: 'black', mb: 2 }}>
								Registration open
							</Typography>
							<Divider />
							<Typography variant="body1" sx={{ mt: 2, color: "#5E8D66" }}>
								6:00PM - 8:00PM, at Sheraton Xuzhou Hotel
							</Typography>
							<Typography variant="body1" sx={{ mt: 2, color: 'black', mb: 2 }}>
								Welcome drink
							</Typography>
						</Stack>
					</Box>
				</Paper>
				<Paper square
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						boxShadow: 3,
						width: '350px',
						minHeight: '450px',
						backgroundColor: 'white',
						textTransform: 'none',
						textDecoration: 'none'
					}}
				>
					<Box sx={{ bgcolor: 'transparent', width: '100%', padding: 3 }}>
						<Stack spacing={0} sx={{ width: '100%' }}>
							<Typography variant="h6" sx={{ fontWeight: "bold", color: '#3E4F44', mb: 2 }}>
								12 Apr
							</Typography>
							<Divider />
							<Typography variant="body1" sx={{ mt: 2, color: "#5E8D66" }}>
								8:00AM - 5:30PM, at Sheraton Xuzhou Hotel
							</Typography>
							<Typography variant="body1" sx={{ mt: 2, color: 'black', mb: 2 }}>
								Plenary presentation
							</Typography>
							<Divider />
							<Typography variant="body1" sx={{ mt: 2, color: "#5E8D66" }}>
								6:30PM - 10:00PM, at Sheraton Xuzhou Hotel
							</Typography>
							<Typography variant="body1" sx={{ mt: 2, color: 'black', mb: 2 }}>
								Dinner
							</Typography>
						</Stack>
					</Box>
				</Paper>
				<Paper square
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						boxShadow: 3,
						width: '350px',
						minHeight: '450px',
						backgroundColor: 'white',
						textTransform: 'none',
						textDecoration: 'none'
					}}
				>
					<Box sx={{ bgcolor: 'transparent', width: '100%', padding: 3 }}>
						<Stack spacing={0} sx={{ width: '100%' }}>
							<Typography variant="h6" sx={{ fontWeight: "bold", color: '#3E4F44', mb: 2 }}>
								13 Apr
							</Typography>
							<Divider />
							<Typography variant="body1" sx={{ mt: 2, color: "#5E8D66" }}>
								8:00AM - 12:30PM, at Sheraton Xuzhou Hotel
							</Typography>
							<Typography variant="body1" sx={{ mt: 2, color: 'black' }}>
								Parallel sessions
							</Typography>
							<Typography variant="body1" sx={{ mt: 2, color: 'black' }}>
								Session A: Coal Mine Methane / Coal Bed Methane;
							</Typography>
							<Typography variant="body1" sx={{ mt: 2, color: 'black' }}>
								Session B: CCS;
							</Typography>
							<Typography variant="body1" sx={{ mt: 2, color: 'black', mb: 2 }}>
								Session C: Young Researcher Forum.
							</Typography>
							<Divider />
							<Typography variant="body1" sx={{ mt: 2, color: "#5E8D66" }}>
								2:00PM - 5:00PM, at Xuzhou Museum
							</Typography>
							<Typography variant="body1" sx={{ mt: 2, color: 'black', mb: 2 }}>
								Xuzhou culture visit
							</Typography>
						</Stack>
					</Box>
				</Paper>
				<Paper square
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						boxShadow: 3,
						width: '350px',
						minHeight: '450px',
						backgroundColor: 'white',
						textTransform: 'none',
						textDecoration: 'none'
					}}
				>
					<Box sx={{ bgcolor: 'transparent', width: '100%', padding: 3 }}>
						<Stack spacing={0} sx={{ width: '100%' }}>
							<Typography variant="h6" sx={{ fontWeight: "bold", color: '#3E4F44', mb: 2 }}>
								14 Apr
							</Typography>
							<Divider />
							<Typography variant="body1" sx={{ mt: 2, color: "#5E8D66" }}>
								Optional
							</Typography>
							<Typography variant="body1" sx={{ mt: 2, color: 'black', mb: 2 }}>
								Site visit
							</Typography>
						</Stack>
					</Box>
				</Paper>
			</Box>
			<Box
        sx={{
          display: { xs: 'flex', md: 'none' },
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: 5,
          position: 'relative',
          width: '100%',
          bgcolor: 'white',
          pl: 5,
          pr: 5,
          pt: 10,
					mb: 10
        }}
      >
				<Paper square
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						boxShadow: 3,
						width: '350px',
						minHeight: '450px',
						backgroundColor: 'white',
						textTransform: 'none',
						textDecoration: 'none'
					}}
				>
					<Box sx={{ bgcolor: 'transparent', width: '100%', padding: 3 }}>
						<Stack spacing={0} sx={{ width: '100%' }}>
							<Typography variant="h6" sx={{ fontWeight: "bold", color: '#3E4F44', mb: 2 }}>
								11 Apr
							</Typography>
							<Divider />
							<Typography variant="body1" sx={{ mt: 2, color: "#5E8D66" }}>
								9:00AM - 5:30PM, at Sheraton Xuzhou Hotel
							</Typography>
							<Typography variant="body1" sx={{ mt: 2, color: 'black', mb: 2 }}>
								Registration open
							</Typography>
							<Divider />
							<Typography variant="body1" sx={{ mt: 2, color: "#5E8D66" }}>
								6:00PM - 8:00PM, at Sheraton Xuzhou Hotel
							</Typography>
							<Typography variant="body1" sx={{ mt: 2, color: 'black', mb: 2 }}>
								Welcome drink
							</Typography>
						</Stack>
					</Box>
				</Paper>
				<Paper square
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						boxShadow: 3,
						width: '350px',
						minHeight: '450px',
						backgroundColor: 'white',
						textTransform: 'none',
						textDecoration: 'none'
					}}
				>
					<Box sx={{ bgcolor: 'transparent', width: '100%', padding: 3 }}>
						<Stack spacing={0} sx={{ width: '100%' }}>
							<Typography variant="h6" sx={{ fontWeight: "bold", color: '#3E4F44', mb: 2 }}>
								12 Apr
							</Typography>
							<Divider />
							<Typography variant="body1" sx={{ mt: 2, color: "#5E8D66" }}>
								8:00AM - 5:30PM, at Sheraton Xuzhou Hotel
							</Typography>
							<Typography variant="body1" sx={{ mt: 2, color: 'black', mb: 2 }}>
								Plenary presentation
							</Typography>
							<Divider />
							<Typography variant="body1" sx={{ mt: 2, color: "#5E8D66" }}>
								6:30PM - 10:00PM, at Sheraton Xuzhou Hotel
							</Typography>
							<Typography variant="body1" sx={{ mt: 2, color: 'black', mb: 2 }}>
								Dinner
							</Typography>
						</Stack>
					</Box>
				</Paper>
				<Paper square
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						boxShadow: 3,
						width: '350px',
						minHeight: '450px',
						backgroundColor: 'white',
						textTransform: 'none',
						textDecoration: 'none'
					}}
				>
					<Box sx={{ bgcolor: 'transparent', width: '100%', padding: 3 }}>
						<Stack spacing={0} sx={{ width: '100%' }}>
							<Typography variant="h6" sx={{ fontWeight: "bold", color: '#3E4F44', mb: 2 }}>
								13 Apr
							</Typography>
							<Divider />
							<Typography variant="body1" sx={{ mt: 2, color: "#5E8D66" }}>
								8:00AM - 12:30PM, at Sheraton Xuzhou Hotel
							</Typography>
							<Typography variant="body1" sx={{ mt: 2, color: 'black' }}>
								Parallel sessions
							</Typography>
							<Typography variant="body1" sx={{ mt: 2, color: 'black' }}>
								Session A: Coal Mine Methane / Coal Bed Methane;
							</Typography>
							<Typography variant="body1" sx={{ mt: 2, color: 'black' }}>
								Session B: CCS;
							</Typography>
							<Typography variant="body1" sx={{ mt: 2, color: 'black', mb: 2 }}>
								Session C: Young Researcher Forum.
							</Typography>
							<Divider />
							<Typography variant="body1" sx={{ mt: 2, color: "#5E8D66" }}>
								2:00PM - 5:00PM, at Xuzhou Museum
							</Typography>
							<Typography variant="body1" sx={{ mt: 2, color: 'black', mb: 2 }}>
								Xuzhou culture visit
							</Typography>
						</Stack>
					</Box>
				</Paper>
				<Paper square
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						boxShadow: 3,
						width: '350px',
						minHeight: '450px',
						backgroundColor: 'white',
						textTransform: 'none',
						textDecoration: 'none'
					}}
				>
					<Box sx={{ bgcolor: 'transparent', width: '100%', padding: 3 }}>
						<Stack spacing={0} sx={{ width: '100%' }}>
							<Typography variant="h6" sx={{ fontWeight: "bold", color: '#3E4F44', mb: 2 }}>
								14 Apr
							</Typography>
							<Divider />
							<Typography variant="body1" sx={{ mt: 2, color: "#5E8D66" }}>
								Optional
							</Typography>
							<Typography variant="body1" sx={{ mt: 2, color: 'black', mb: 2 }}>
								Site visit
							</Typography>
						</Stack>
					</Box>
				</Paper>
			</Box>
		</Stack>
	);
};

export default Program;