import React, { useState, useEffect, useRef } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  MenuList,
  MenuItem,
  Container,
  Box,
  IconButton,
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemText,
  Collapse,
  Popper,
  Paper,
  ClickAwayListener,
  useMediaQuery,
  Fade
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import LangSwitcher from './utils/LangSwitcher';

import '@fontsource/outfit/700.css';

const Navbar = () => {
  // localisation with route prefix
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const currentLang = location.pathname.split('/')[1];

  // handle 2 fullscreen navbar menu
  const [showMenu1, setShowMenu1] = useState(false);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [p1Hovered, setP1Hovered] = useState(false);
  const p1Ref = useRef(null);

  const [showMenu2, setShowMenu2] = useState(false);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [p2Hovered, setP2Hovered] = useState(false);
  const p2Ref = useRef(null);

  // handle mobile & etc navbar drawer
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  // for expandable drawer menu items
  const [attendExp, setAttendExp] = useState(false);
  const [programExp, setProgramExp] = useState(false);

  // this is used to check screen size
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  // Following functions are used to manipulate the 'attend' popper,
  // close 'Program' popper when 'Attend' popper is opened
  const handleB1Enter = (event) => {
    setShowMenu1(true);
    setAnchorEl1(event.currentTarget);
    setShowMenu2(false);
    setP2Hovered(false);
    setAnchorEl2(null);
  };
  const handleB1Leave = (event) => {
    setTimeout(() => {
      if (p1Ref.current) {
        console.log(p1Ref.current.getBoundingClientRect());
        console.log(`event.clientX: ${event.clientX}, event.clientY: ${event.clientY}`);

        const rect = p1Ref.current.getBoundingClientRect();
        const isHover =
          event.clientX >= rect.left &&
          event.clientX <= rect.right &&
          event.clientY >= rect.top &&
          event.clientY <= rect.bottom;
        setP1Hovered(isHover);
        if (!isHover) {
          setShowMenu1(false);
          setAnchorEl1(null);
        }
      }
    }, 50)
    /*if (!p1Hovered) {
      setShowMenu1(false);
      setAnchorEl1(null);
    }*/
  }
  const handleP1Enter = () => {
    setP1Hovered(true);
  }
  const handleP1Leave = () => {
    setP1Hovered(false);
    setShowMenu1(false);
    setAnchorEl1(null);
  }
  const handleClose1 = (event) => {
    if (anchorEl1) {
      if (anchorEl1.current && anchorEl1.current.contains(event.target)) {
        return;
      }
    }
    setShowMenu1(false);
    setP1Hovered(false);
    setAnchorEl1(null);
  };

  // Following functions are used to manipulate the 'program' popper
  const handleB2Enter = (event) => {
    setShowMenu2(true);
    setAnchorEl2(event.currentTarget);
    setShowMenu1(false);
    setP1Hovered(false);
    setAnchorEl1(null);
  };
  const handleB2Leave = (event) => {
    setTimeout(() => {
      if (p2Ref.current) {
        console.log(p2Ref.current.getBoundingClientRect());
        console.log(`event.clientX: ${event.clientX}, event.clientY: ${event.clientY}`);

        const rect = p2Ref.current.getBoundingClientRect();
        const isHover =
          event.clientX >= rect.left &&
          event.clientX <= rect.right &&
          event.clientY >= rect.top &&
          event.clientY <= rect.bottom;
        setP2Hovered(isHover);
        if (!isHover) {
          setShowMenu2(false);
          setAnchorEl2(null);
        }
      }
    }, 50)
    /*if (!p2Hovered) {
      setShowMenu2(false);
      setAnchorEl2(null);
    }*/
  }
  const handleP2Enter = () => {
    setP2Hovered(true);
  }
  const handleP2Leave = () => {
    setP2Hovered(false);
    setShowMenu2(false);
    setAnchorEl2(null);
  }
  const handleClose2 = (event) => {
    if (anchorEl2) {
      if (anchorEl2.current && anchorEl2.current.contains(event.target)) {
        return;
      }
    }
    setShowMenu2(false);
    setP2Hovered(false);
    setAnchorEl2(null);
  };

  useEffect(() => {
    if (isLargeScreen) {
      setShowDrawer(false);
      setDrawerOpen(false);
    }
  }, [isLargeScreen]);

  const handleAttendExp = () => {
    setAttendExp(!attendExp);
  };

  const handleProgramExp = () => {
    setProgramExp(!programExp);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (!open) {
      setAttendExp(false);
      setProgramExp(false);
    }
    setShowDrawer(open);
    setDrawerOpen(open);
  };

  const clickIcon = () => {
    setDrawerOpen(!drawerOpen);
    setShowDrawer(!showDrawer);
  };

  const drawerList = () => (
    <Box
      sx={{ width: 'auto' }}
      onKeyDown={toggleDrawer(false)}
    >
      <Box sx={{ height: 64 }} />
      <ListItem disablePadding>
        <ListItemButton onClick={handleAttendExp}>
          <ListItemText primary={t('navbar.attend')} primaryTypographyProps={{ variant: 'subtitle1' }} />
          {attendExp ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemButton>
      </ListItem>
      <Collapse in={attendExp} timeout="auto" unmountOnExit>
        <List>
          <ListItem disablePadding>
            <ListItemButton
              component={Link} to={`/${currentLang}/attend/register-info`}
              onClick={toggleDrawer(false)}
            >
              <ListItemText primary={t('navbar.register')} primaryTypographyProps={{ variant: 'subtitle1' }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              component={Link} to={`/${currentLang}/attend/venue`}
              onClick={toggleDrawer(false)}
            >
              <ListItemText primary={t('navbar.venue')} primaryTypographyProps={{ variant: 'subtitle1' }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              component={Link} to={`/${currentLang}/attend/faq`}
              onClick={toggleDrawer(false)}
            >
              <ListItemText primary={t('navbar.faq')} primaryTypographyProps={{ variant: 'subtitle1' }} />
            </ListItemButton>
          </ListItem>
        </List>
      </Collapse>
      <Divider />
      <ListItem disablePadding>
        <ListItemButton onClick={handleProgramExp}>
          <ListItemText primary={t('navbar.program')} primaryTypographyProps={{ variant: 'subtitle1' }} />
          {programExp ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemButton>
      </ListItem>
      <Collapse in={programExp} timeout="auto" unmountOnExit>
        <List>
          <ListItem disablePadding>
            <ListItemButton
              component={Link} to={`/${currentLang}/program/conference-program`}
              onClick={toggleDrawer(false)}
            >
              <ListItemText primary={t('navbar.conference-program')} primaryTypographyProps={{ variant: 'subtitle1' }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              component={Link} to={`/${currentLang}/program/keynote-speakers`}
              onClick={toggleDrawer(false)}
            >
              <ListItemText primary={t('navbar.speakers')} primaryTypographyProps={{ variant: 'subtitle1' }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              component={Link} to={`/${currentLang}/program/call-for-abstracts`}
              onClick={toggleDrawer(false)}
            >
              <ListItemText primary={t('navbar.abstracts')} primaryTypographyProps={{ variant: 'subtitle1' }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              component={Link} to={`/${currentLang}/program/seed-fund`}
              onClick={toggleDrawer(false)}
            >
              <ListItemText primary={t('navbar.seedfund')} primaryTypographyProps={{ variant: 'subtitle1' }} />
            </ListItemButton>
          </ListItem>
        </List>
      </Collapse>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton
            component={Link} to={`/${currentLang}/sponsors`}
            onClick={toggleDrawer(false)}
          >
            <ListItemText primary={t('navbar.sponsors')} primaryTypographyProps={{ variant: 'subtitle1' }} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            component={Link} to={`/${currentLang}/committee`}
            onClick={toggleDrawer(false)}
          >
            <ListItemText primary={t('navbar.committee')} primaryTypographyProps={{ variant: 'subtitle1' }} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            component={Link} to={`/${currentLang}/contact`}
            onClick={toggleDrawer(false)}
          >
            <ListItemText primary={t('navbar.contact')} primaryTypographyProps={{ variant: 'subtitle1' }} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            component={Link} to={`/${currentLang}/attend/register-info`}
            onClick={toggleDrawer(false)}
          >
            <ListItemText primary={t('navbar.button')} primaryTypographyProps={{ variant: 'subtitle1' }} sx={{ color: "#3E4F44", textDecoration: 'underline' }} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "#5E8D66", boxShadow: 'none' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component={Link} to={`/${currentLang}/`}
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              color: 'inherit',
              textDecoration: 'none',
              fontFamily: '"Outfit", sans-serif',
              fontWeight: 700,
            }}
            onClick={toggleDrawer(false)}
          >
            AUCN DeCarbon
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={clickIcon}>
              {drawerOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
            <Drawer
              anchor="top"
              position="absolute"
              open={showDrawer}
              onClose={toggleDrawer(false)}
              sx={{
                zIndex: (theme) => theme.zIndex.drawer,
                overflow: 'auto'
              }}
            >
              {drawerList()}
            </Drawer>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component={Link} to={`/${currentLang}/`}
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              color: 'inherit',
              textDecoration: 'none',
              fontFamily: '"Outfit", sans-serif',
              fontWeight: 700,
            }}
            onClick={toggleDrawer(false)}
          >
            AUCN DeCarbon
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Box sx={{ flexGrow: 0 }}>
              <Button
                ref={anchorEl1}
                color='inherit'
                aria-controls={showMenu1 ? 'attend-menu' : undefined}
                aria-haspopup="true"
                onMouseEnter={handleB1Enter}
                onMouseLeave={handleB1Leave}
                sx={{ textTransform: 'none' }}
              >
                {t('navbar.attend')}
              </Button>
              <Popper
                id="popper1"
                open={showMenu1}
                anchorEl={anchorEl1}
                placement='bottom-start'
                disablePortal
                transition
                onMouseEnter={handleP1Enter}
                onMouseLeave={handleP1Leave}
                sx={{ textTransform: 'none' }}
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper square elevation={0} sx={{ bgcolor: '#C2D6C5', boxShadow: 3 }} ref={p1Ref}>
                      <ClickAwayListener onClickAway={handleClose1}>
                        <MenuList id="attend-menu">
                          <MenuItem
                            onClick={handleClose1}
                            component={Link} to={`/${currentLang}/attend/register-info`}
                            sx={{ typography: 'subtitle1' }}
                          >{t('navbar.register')}</MenuItem>
                          <MenuItem
                            onClick={handleClose1}
                            component={Link} to={`/${currentLang}/attend/venue`}
                            sx={{ typography: 'subtitle1' }}
                          >{t('navbar.venue')}</MenuItem>
                          <MenuItem
                            onClick={handleClose1}
                            component={Link} to={`/${currentLang}/attend/faq`}
                            sx={{ typography: 'subtitle1' }}
                          >{t('navbar.faq')}</MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Fade>
                )}
              </Popper>
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <Button
                color="inherit"
                aria-controls={showMenu2 ? 'program-menu' : undefined}
                aria-haspopup="true"
                onMouseEnter={handleB2Enter}
                onMouseLeave={handleB2Leave}
                sx={{ textTransform: 'none' }}
              >
                {t('navbar.program')}
              </Button>
              <Popper
                id="popper2"
                open={showMenu2}
                anchorEl={anchorEl2}
                placement='bottom-start'
                disablePortal
                transition
                onMouseEnter={handleP2Enter}
                onMouseLeave={handleP2Leave}
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper square elevation={0} sx={{ bgcolor: '#C2D6C5', boxShadow: 3 }} ref={ p2Ref }>
                      <ClickAwayListener onClickAway={handleClose2}>
                        <MenuList id="program-menu">
                          <MenuItem
                            onClick={handleClose2}
                            component={Link} to={`/${currentLang}/program/conference-program`}
                            sx={{ typography: 'subtitle1' }}
                          >{t('navbar.conference-program')}</MenuItem>
                          <MenuItem
                            onClick={handleClose2}
                            component={Link} to={`/${currentLang}/program/keynote-speakers`}
                            sx={{ typography: 'subtitle1' }}
                          >{t('navbar.speakers')}</MenuItem>
                          <MenuItem
                            onClick={handleClose2}
                            component={Link} to={`/${currentLang}/program/call-for-abstracts`}
                            sx={{ typography: 'subtitle1' }}
                          >{t('navbar.abstracts')}</MenuItem>
                          <MenuItem
                            onClick={handleClose2}
                            component={Link} to={`/${currentLang}/program/seed-fund`}
                            sx={{ typography: 'subtitle1' }}
                          >{t('navbar.seedfund')}</MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Fade>
                )}
              </Popper>
            </Box>
            <Button
              color="inherit"
              sx={{ textTransform: 'none' }}
              component={Link} to={`/${currentLang}/sponsors`}
            >
              {t('navbar.sponsors')}
            </Button>
            <Button
              color="inherit"
              sx={{ textTransform: 'none' }}
              component={Link} to={`/${currentLang}/committee`}
            >
              {t('navbar.committee')}
            </Button>
            <Button
              edge="end"
              color="inherit"
              sx={{ textTransform: 'none' }}
              component={Link} to={`/${currentLang}/contact`}
            >
              {t('navbar.contact')}
            </Button>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <LangSwitcher />
          </Box>
          <Button
            component={Link} to={`/${currentLang}/attend/register-info`}
            variant='contained'
            sx={{
              ml: 2,
              color: '#3E4F44',
              textTransform: 'none',
              backgroundColor: '#C2D6C5',
              display: { xs: 'none', md: 'flex' },
              '&:hover': {
                backgroundColor: 'transparent',
                color: 'white',
                boxShadow: 'none'
              }
            }}
          >
            {t('navbar.button')}
          </Button>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
